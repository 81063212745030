:root {
    --main-color: #327DF3;
    --main-color-rgb: 50, 125, 243;
    --background-second-color: rgb(0, 0, 0);
    --background: linear-gradient(
        0deg,
        var(--main-color),
        var(--background-second-color)
    );
    --main-text-color: #fff;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    background: var(--background);
    color: var(--main-text-color);
    font-family: DM Sans Regular,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
}

html, body {
    height: 100%;
}

code {
    display: block;
    white-space: pre-wrap;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

#root {
    width: 100%;
    height: 100%;
}

#root .content {
    height: calc(100% - var(--navbar-height));
}

#root .content.vertical {
    padding-top: 0;
    height: 100%;
}

.form-control::placeholder {
    color:rgba(255, 255, 255, 0.466);
}

.form-control, .form-control:focus {
    background: rgba(255, 255, 255, 0.15);
    color: var(--main-text-color);
    border: 0;
}

.form-control:focus {
    box-shadow: 0 0 0 .25rem rgba(var(--main-color-rgb), .25);
}

.form-control:disabled, .form-control[readonly] {
    background: rgba(255,255,255,0.1);
    opacity: .5;
}

.form-group {
    margin-bottom: 1rem;
}

.input-group-text {
    border: 0;
}

.input-group .form-control {
    margin: 0;
}

.offcanvas {
    background-color: rgba(0,0,0,.75);
}

.btn-close {
    background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;;
}