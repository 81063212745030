:root {
    --navbar-height: 4rem;
}

.navbar {
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 0;
    width: 100%;
    height: var(--navbar-height);
    padding: 0 2rem;
}

.navbar .btn {
    color: var(--main-text-color);
    font-size: 1.5rem;
    padding: 0;
    margin-left: 1rem;
}

.navbar .btn:focus {
    box-shadow: none;
}

.navbar .btn.go-back {
    border: 1px solid rgba(255, 255, 255, 0.384);
    border-radius: 1rem;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    font-size: 1.5rem;
}

.navbar .navbar-left,
.navbar .navbar-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.navbar .navbar-left {
    width: 75%;
}

.navbar .navbar-buttons {
    width: 25%;
    justify-content: flex-end;
}

.navbar .logo {
    margin-right: 1rem;
    max-height: 2rem;
}