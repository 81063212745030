:root {
    --search-bar-border-radius: 2rem;
    --search-bar-init-width: 10rem;
    --search-bar-final-width: min(90%, max(40rem, 25vw));
}

@keyframes search-bar-ani {
    0% {
        width: var(--search-bar-init-width);
    }
    100% {
        width: var(--search-bar-final-width);
    }
}

.search-bar {
    display: flex;
    flex-direction: row;
    width: var(--search-bar-final-width);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.171);
    border-radius: var(--search-bar-border-radius);
    animation: search-bar-ani 1.2s forwards;
}

.search-bar.no-animation {
    animation: none;
}

.search-bar .form-control {
    font-size: 1rem;
    padding-left: 1.5rem;
    padding-right: 6rem;
}

.search-bar .btn {
    display: flex;
    align-items: center;
    padding: 0 1.2rem;
    z-index: 5;
}

.search-bar .form-control,
.search-bar .btn {
    border-radius: var(--search-bar-border-radius) !important;
}

.search-bar .form-control:focus,
.search-bar .btn:focus {
    box-shadow: none;
}

.search-bar .btn {
    padding: 0 .5rem;
    background: transparent;
    position: absolute;
    height: 100%;
    border: 0;
    font-size: 1rem;
}

.search-bar .btn-search {
    padding-left: 1rem;
    right: 1rem;
}

.search-bar .btn-search::after {
    position: absolute;
    left: 0;
    content: "";
    height: 60%;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left: 1px solid rgba(255,255,255,.2);
}

.search-bar .btn-search .spinner-border {
    width: 1rem;
    height: 1rem;
}

.search-bar .btn-clear {
    right: 4rem
}

.search-bar .rbt {
    width: 100%;
}